import React, { useEffect, useRef, useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTeamLeaders, markaterRigister } from "../store/markaterSlice";
import Loading from "../components/Loading";
import Select from "react-select";


function Home() {
  const simpleValidator = useRef(new SimpleReactValidator());
  const teamLeaders = useSelector((state)=> state.markaterSlice.teamLeaders);
  const [, forceUpdate] = useState();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [selectVal, setSelectVal] = useState(true);
  const [optionselect, setOptionSelect] = useState();
  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    name: "",
    phone: "",
    password: "",
    teamLeaderId: "",
    balacnce: 0,
  });

  useEffect(() => {
    dispatch(getTeamLeaders())
      .unwrap()
      .then((data) => {
        // console.log(data);
        const newTeamLeaders = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setOptionSelect(newTeamLeaders);
        setLoading(false)
      }).catch((error) => {
        toast.error(error.response.message);
        setLoading(false)
      });
  }, [dispatch]);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const onSelectChange = (value, action) => {
    const teamleaderid = value.value;
    setSelectVal({
      value: value.value,
      label: value.label,
    })
    setInputs((prevInputs) => ({ ...prevInputs, teamLeaderId: teamleaderid }));
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    if (simpleValidator.current.allValid()) {
      console.log(inputs)
      dispatch(markaterRigister(inputs))
        .unwrap()
        .then((res) => {
          toast.success("تم التسجيل بنجاح سيتم توجيهك لصفحة التسويق");
          console.log(res);
          setTimeout(() => {
            navigate(`/affiliatemarketing/${res.id}`);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          toast.error(err.response.data);
          setLoading(false);
        });
    } else {
      setLoading(false);
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  };

  return (
    <div className="home-page">
      {loading ? <Loading /> : null}
      <div className="container">
        <div className="sectoin_header">
          <h5>مرحبا بك في التسويق بالعموله</h5>
        </div>
        <div className="section_content">
          <p>
            هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا
            النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد
            من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.
          </p>
          <p>
            إذا كنت تحتاج إلى عدد أكبر من الفقرات يتيح لك مولد النص العربى زيادة
            عدد الفقرات كما تريد، النص لن يبدو مقسما ولا يحوي أخطاء لغوية، مولد
            النص العربى مفيد لمصممي المواقع على وجه الخصوص، حيث يحتاج العميل فى
            كثير من الأحيان أن يطلع على صورة حقيقية لتصميم الموقع.
          </p>
          <p>
            ومن هنا وجب على المصمم أن يضع نصوصا مؤقتة على التصميم ليظهر للعميل
            الشكل كاملاً،دور مولد النص العربى أن يوفر على المصمم عناء البحث عن
            نص بديل لا علاقة له بالموضوع الذى يتحدث عنه التصميم فيظهر بشكل لا
            يليق.
          </p>
          <p>
            هذا النص يمكن أن يتم تركيبه على أي تصميم دون مشكلة فلن يبدو وكأنه نص
            منسوخ، غير منظم، غير منسق، أو حتى غير مفهوم. لأنه مازال نصاً بديلاً
            ومؤقتاً.
          </p>
          <p className="note">سجل لتحصل على رابط التسويق الخاص بك</p>
        </div>
        <div className="home_form">
          <form action="" className="form_style" onSubmit={saveData}>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">الاسم</label>
                  <input
                    type="text"
                    name="name"
                    id=""
                    value={inputs.name}
                    className="form-control"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("name")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "name",
                    inputs.name,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">رقم الهاتف</label>
                  <input
                    type="phone"
                    name="phone"
                    id=""
                    value={inputs.phone}
                    className="form-control"
                    onChange={handelInputChange}
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("phone")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "phone",
                    inputs.phone,
                    "required"
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">كلمة المرور</label>
                  <input
                    type="password"
                    name="password"
                    id=""
                    value={inputs.password}
                    onChange={handelInputChange}
                    className="form-control"
                    onBlur={() =>
                      simpleValidator.current.showMessageFor("password")
                    }
                  />
                </div>
                <div className="error">
                  {simpleValidator.current.message(
                    "password",
                    inputs.password,
                    "required"
                  )}
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group">
                  <label htmlFor="">مدير الفريق</label>
                  <Select
                  closeMenuOnSelect={true}
                  name="colors"
                  value={selectVal}
                  options={optionselect}
                  className="basic-multi-select form-control"
                  classNamePrefix="select"
                  id="SkillsTags"
                  onChange={onSelectChange}
                // onInputChange={handelFilterChange}
                // hideSelectedOptions={false}
                />
                  {/* <select name="teamLeaderId" onChange={handelInputChange} className="form-control">
                    <option value="" disabled>اختر</option>
                    {teamLeaders.map((item)=>(
                      <option value={item.id} key={item.id}>{item.name}</option>
                    ))}
                  </select> */}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="form-group">
                  <button className="btn">الحصول على الرابط</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Home;
