import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = "https://backend.egy-courses.com/api/";

export const markaterRigister = createAsyncThunk(
  "markater/rigister",
  async (markaterdata, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        `${endpoint}Markters/register`,
        markaterdata
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markaterLogin = createAsyncThunk(
  "markater/login",
  async (markaterdata, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(`${endpoint}Markters/login`, markaterdata);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const markaterRequests = createAsyncThunk(
  "markater/markaterrequests",
  async ({ markateruserId, page }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}FormRequests?markterId=${markateruserId}&pageNumber=${page}&needPage=true&pageSize=100`
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const formRequests = createAsyncThunk(
  "courses/formrequests",
  async (
    {
      markterId,
      courseId,
      startDate,
      endDate,
      pageNumber,
      courseIds,
      isCalled,
      statusId,
      startChangeDate,
      endChangeDate,
    },
    thunkAPI
  ) => {
    const { rejectWithValue } = thunkAPI;
    if (courseIds.length > 0) {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?statusId=${statusId}&markterId=${markterId}&needPage=true&allData=false&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}${courseIds}&isCalled=${isCalled}&pageNumber=${pageNumber}&startChangeDate=${startChangeDate}&endChangeDate=${endChangeDate}&pageSize=100`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    } else {
      try {
        const res = await axios.get(
          `${endpoint}FormRequests?statusId=${statusId}&markterId=${markterId}&needPage=true&courseId=${courseId}&startDate=${startDate}&endDate=${endDate}&isCalled=${isCalled}&pageNumber=${pageNumber}&startChangeDate=${startChangeDate}&endChangeDate=${endChangeDate}&pageSize=100`
        );
        const data = await res.data;
        // console.log(data);
        return data;
      } catch (error) {
        return rejectWithValue(error);
      }
    }
  }
);

export const markaterInfo = createAsyncThunk(
  "markater/markaterInfo",
  async (markaterId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters/${markaterId}`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const markaterEdit = createAsyncThunk(
  "markater/markaterEdit",
  async ({ markaterId, markaterData }, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.put(
        `${endpoint}Markters/${markaterId}`,
        markaterData
      );
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getTeamLeaders = createAsyncThunk(
  "markater/get-team-leaders",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(`${endpoint}Markters?wantTeamLeader=true`);
      const data = await res.data;
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const markaterSlice = createSlice({
  name: "markater",
  initialState: {
    isLoading: true,
    markterData: {},
    markterDataLogin: {},
    markaterRequestsData: [],
    markaterformRequestsData: [],
    allMarkaterInfo: {},
    teamLeaders: [],
  },
  reducers: {},
  extraReducers: {
    [markaterRigister.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterRigister.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markterData = action.payload;
    },
    [markaterRigister.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterLogin
    [markaterLogin.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterLogin.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markterDataLogin = action.payload;
    },
    [markaterLogin.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterRequests
    [markaterRequests.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaterRequestsData = action.payload.items;
    },
    [markaterRequests.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterInfo
    [markaterInfo.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterInfo.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allMarkaterInfo = action.payload;
    },
    [markaterInfo.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // markaterEdit
    [markaterEdit.pending]: (state, action) => {
      state.isLoading = true;
    },
    [markaterEdit.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [markaterEdit.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getTeamLeaders
    [formRequests.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.teamLeaders = action.payload;
    },
    [formRequests.rejected]: (state, action) => {
      state.isLoading = false;
    },
    //formRequested
    [formRequests.pending]: (state, action) => {
      state.isLoading = true;
    },
    [formRequests.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.markaterformRequestsData = action.payload;
    },
    [formRequests.rejected]: (state, action) => {
      state.isLoading = false;
    },
  },
});

export default markaterSlice.reducer;
