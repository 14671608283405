import React from "react";
import Login from "../Login";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Agentlogin() {
  return (
    <>
      <ToastContainer theme="colored" />

      <Login />
    </>
  );
}

export default Agentlogin;
