import {configureStore} from '@reduxjs/toolkit';
import markaterSlice from './markaterSlice';
import courseSlice from './courseSlice';
import requestSlice from './requestSlice';
export default configureStore({
  reducer: {
    markaterSlice,
    courseSlice,
    requestSlice,
  }
})