import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material";
// import "jspdf-autotable";

// const columns =

export default function DataGridDemo({
  coulmns,
  rows,
  handleRowSelection,
  selectionRows,
}) {
  // console.log("rows");
  console.log(rows, "before");
  rows =
    rows?.length > 0 &&
    rows.map((item) => ({
      ...item, // Keep all the existing properties
      isCalled2: item.isCalled ? "تم الاتصال" : "لم يتم الاتصال",
      orderDate:
        item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
      orderTime:
        item.timeCreated != null
          ? item?.timeCreated.split("T")[1].split(".")[0]
          : "",
      courseModel: item.courseModel?.name,
      orderStatusModel: item.orderStatusModel?.name,
    }));
  console.log(rows, "after");

  const theme = createTheme({
    direction: "rtl",
  });

  function convertToCSV(data) {
    const rows = [];
    const columnHeaders = [];

    // Extract column headers
    const columns = coulmns; // Assuming you have defined your columns somewhere
    for (const column of columns) {
      columnHeaders.push(column.headerName);
    }
    rows.push(columnHeaders.join(","));

    // Extract data rows and modify the "status" field
    for (const row of data) {
      const rowData = columns.map((column) => {
        return row[column.field];
      });
      rows.push(rowData.join(","));
    }

    return rows.join("\n");
  }

  function downloadCSV() {
    const csvData = convertToCSV(rows); // Assuming 'rows' is your data

    // Create a Blob with UTF-8 encoding
    const blob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), csvData], {
      type: "text/csv;charset=utf-8",
    });

    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "exported_data.csv";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ height: 600, width: "100%" }}>
        <DataGrid
          rows={rows}
          columns={coulmns}
          onRowSelectionModelChange={handleRowSelection}
          pageSize={100}
          checkboxSelection={selectionRows}
          sorting={true}
          disableRowSelectionOnClick
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              printOptions: { disableToolbarButton: true },
              csvOptions: { disableToolbarButton: true },
            },
          }}
          localeText={{
            toolbarDensity: "المقاس",
            toolbarDensityLabel: "المقاس",
            toolbarDensityCompact: "صغير",
            toolbarDensityStandard: "متوسط",
            toolbarDensityComfortable: "كبير",
            toolbarFilters: "تصفية",
            toolbarFiltersLabel: "تصفية",
            toolbarFiltersTooltipHide: "إخفاء التصفية",
            toolbarFiltersTooltipShow: "عرض التصفية",
            filterOperatorContains: "يحتوي",
            filterOperatorEquals: "مساوٍ لـ",
            filterOperatorNotEquals: "غير مساوٍ لـ",
            filterOperatorStartsWith: "يبدأ بـ",
            filterOperatorEndsWith: "ينتهي بـ",
            filterOperatorIs: "هو",
            filterOperatorIsNot: "ليس هو",
            filterOperatorIsEmpty: "فارغ",
            filterOperatorIsNotEmpty: "ليس فارغ",
            toolbarFiltersTooltipHide: "إخفاء التصفية",
            toolbarFiltersTooltipShow: "عرض التصفية",
            toolbarExport: "تصدير",
            toolbarExportLabel: "تصدير",
            toolbarExportCSV: "تصدير كملف CSV",
            toolbarExportPrint: "طباعه",
            toolbarColumns: "اعمده",
            // Add more translations as needed
            // columnMenuUnsort: "",
            columnMenuSortAsc: "ترتيب تصاعدي",
            columnMenuSortDesc: "ترتيب تنازلي",
            columnMenuFilter: "تصفيه",
            columnMenuHideColumn: "اخفاء العمود",
            columnMenuManageColumns: "اداره الاعمده",
          }}
        />
      </Box>
    </ThemeProvider>
  );
}
