import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = 'https://backend.egy-courses.com/api/'



export const getAllCourses = createAsyncThunk(
  "courses/getAllCourses",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}Courses`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
); 


export const getCourseId = createAsyncThunk(
  "courses/getCourseId",
  async (courseId, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.get(
        `${endpoint}Courses/${courseId}`
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
); 




const courseSlice = createSlice({
  name: "courses",
  initialState: {
    isLoading: true,
    allcourses: [],
    singleCourse: {},
  },
  reducers: {},
  extraReducers: {
    [getAllCourses.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getAllCourses.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.allcourses = action.payload;
    },
    [getAllCourses.rejected]: (state, action) => {
      state.isLoading = false;
    },

    // getCourseId
    [getCourseId.pending]: (state, action) => {
      state.isLoading = true;
    },
    [getCourseId.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.singleCourse = action.payload;
    },
    [getCourseId.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default courseSlice.reducer;