import React from "react";
import { Outlet } from "react-router-dom";
import Agentlogin from "./Agentlogin";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";

function AgentOutlet() {
  const user_id = Cookies.get("user_id");
  const phone = Cookies.get("user_phone");
  if (user_id == null || phone == null) return <Agentlogin />;
  if (user_id == "" || phone == "") return <Agentlogin />;

  return (
    <>
      <ToastContainer theme="colored" />
      <div className="App">
        <Outlet />
      </div>
    </>
  );
}

export default AgentOutlet;
