import React from "react";
import { Link } from "react-router-dom";

function CustomNavbar() {
  return (
    <div className="custom_navbar">
      <div className="container">
        <ul className="list-unstyled p-0 d-flex m-0">
          <li>
            <Link to="/agent">
              <i className="fas fa-user"></i>
              <span>تسجيل الدخول</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default CustomNavbar;
