import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAllCourses, getCourseId } from "../store/courseSlice";
import { mainUrl } from "../components/MainUrls";
import CourseForm from "../components/CourseForm";
import { toast } from "react-toastify";
import Loading from "../components/Loading";
import { saverequest } from "../store/requestSlice";
import SimpleReactValidator from "simple-react-validator";

function CourseDetails() {
  const params = useParams();
  const dispatch = useDispatch();
  const courseId = params.id;
  const markterId = params.markterId;
  const courseName = params.coursename;
  const allcourses = useSelector((state) => state.courseSlice.allcourses);
  const simpleValidator = useRef(new SimpleReactValidator());
  const [, forceUpdate] = useState();
  const [loading, setLoading] = useState(true);
  const [singleCourse, setsingleCourse] = useState({});
  const [inputs, setInputs] = useState({
    arName: "",
    enName: "",
    phone1: "",
    phone2: "",
    education: "",
    country: "",
    city: "",
    courseId: courseId,
    orderStatusId: "4eb73a8b-1cbf-4829-f25c-08dbc710612b",
    markterId: markterId,
    isCalled: false,
    qes: "",
  });

  useEffect(() => {
    // const courseName2 = params.urlCourseName;
    document.title = courseName;
    dispatch(getCourseId(courseId))
      .unwrap()
      .then((data) => {
        setsingleCourse(data);
      });
    dispatch(getAllCourses())
      .unwrap()
      .then((res) => {
        // console.log(res)
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.title);
        console.log(err.response);
      });
  }, [dispatch, courseId, markterId]);

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(inputs);

    if (simpleValidator.current.allValid()) {
      dispatch(saverequest(inputs))
        .unwrap()
        .then((res) => {
          toast.success(
            "تم التسجيل بالنجاح وسيتم توجيهك لصفحة الفيسبوك الخاصه بنا"
          );
          setTimeout(() => {
            window.location.href = "https://www.facebook.com/ELQARIAALZAKIA2";
            setLoading(false);
          }, 2000);

          // setLoading(false);
          // setInputs((prevInputs) => ({
          //   ...prevInputs,
          //   arName: "",
          //   enName: "",
          //   phone1: "",
          //   phone2: "",
          //   education: "",
          //   country: "",
          //   city: "",
          //   courseId: "",
          //   qes: "",
          // }));
        })
        .catch((err) => {
          toast.error(err.response.data.title);
          setLoading(false);
        });
    } else {
      toast.error(`من فضلك اكمل البيانات`);
      simpleValidator.current.showMessages();
      forceUpdate(1);
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className="course_details_page">
        <div className="card_img">
          <div className="img_parent">
            <img
              className="main_img"
              src={`${mainUrl}/${singleCourse.imageUrl}`}
              alt=""
            />
          </div>
          <div className="img_parent">
            <img
              className="sub_img"
              src={`${mainUrl}/${singleCourse.imageUrl}`}
              alt=""
            />
          </div>
        </div>
        <div className="container">
          {singleCourse ? (
            singleCourse.isActive ? (
              <>
                <h5 className="head">تفاصيل الكورس</h5>
                {/* <p>{singleCourse.description}</p> */}
                <p
                  className="description-desc"
                  dangerouslySetInnerHTML={{ __html: singleCourse.description }}
                ></p>
                <CourseForm
                  inputs={inputs}
                  saveData={saveData}
                  handelInputChange={handelInputChange}
                  allcourses={allcourses}
                  courseId={courseId}
                  simpleValidator={simpleValidator}
                  setInputs={setInputs}
                />
              </>
            ) : (
              // <Loading />
              <h5 className="not-avilable"></h5>
            )
          ) : (
            <Loading />
          )}
        </div>
      </div>
    </>
  );
}

export default CourseDetails;
