import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Home from "./routes/Home";
import Affiliatemarketing from "./routes/Affiliatemarketing";
import AgentOutlet from "./routes/user/AgentOutlet";
import Agentlogin from "./routes/user/Agentlogin";
import './css/style.css'
import store from "./store";
import AgentDashboard from "./routes/user/AgentDashboard";
import CourseDetails from "./routes/CourseDetails";



const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      { index: true, element: <Home /> },
      { path: "affiliatemarketing/:id", element: <Affiliatemarketing /> },
      { path: "course-details/:id/:markterId/:coursename", element: <CourseDetails /> },
    ]
  },

  {
    path: "/agent",
    element: <AgentOutlet />,
    children: [
      { index: true, element: <Agentlogin /> },
      { path: "dashboard", element: <AgentDashboard /> },
    ]
  },

]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <RouterProvider router={routes} />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
