import { Outlet } from "react-router-dom";
import CustomNavbar from "./components/CustomNavbar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <div className="App">
      <ToastContainer theme="colored" />
      <CustomNavbar />
      <div className="main_content">
        <div className="main_content_wrapper">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default App;
