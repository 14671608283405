import React, { useEffect, useState, useRef } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import CountrySelector from "./CountrySelect";

// import SimpleReactValidator from "simple-react-validator";

function CourseForm({
  inputs,
  setInputs,
  saveData,
  handelInputChange,
  allcourses,
  courseId,
  simpleValidator,
}) {
  // const simpleValidator = useRef(new SimpleReactValidator());
  // const [, forceUpdate] = useState();
  const [phone1, setPhone1] = useState();
  const [phone2, setPhone2] = useState();
  const handelphoneChange = (type1, e) => {
    console.log(e);
    if (type1 === "ph1") {
      setInputs((prevInputs) => ({ ...prevInputs, phone1: e }));
    }
    if (type1 === "ph2") {
      setInputs((prevInputs) => ({ ...prevInputs, phone2: e }));
    }
  };

  return (
    <form action="" onSubmit={saveData} className="form_style">
      <div className="row">
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">الاسم</label>
            <input
              type="text"
              name="arName"
              value={inputs.arName}
              onChange={handelInputChange}
              id=""
              className="form-control"
              onBlur={() => simpleValidator.current.showMessageFor("arName")}
            />
            <div className="error">
              {simpleValidator.current.message(
                "name",
                inputs.arName,
                "required"
              )}
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">الاسم باللغه الانجليزيه</label>
            <input
              type="text"
              name="enName"
              value={inputs.enName}
              onChange={handelInputChange}
              id=""
              className="form-control"
            />
          </div>
        </div> */}
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">رقم الهاتف 1</label>
            {/* <input
              type="number"
              name="phone1"
              value={inputs.phone1}
              onChange={handelInputChange}
              id=""
              className="form-control"
              onBlur={() => simpleValidator.current.showMessageFor("phone1")}
            /> */}
            <PhoneInput
              className="form-control"
              international
              countrySelectProps={{ unicodeFlags: true }}
              placeholder="Enter phone number"
              defaultCountry="EG"
              countryCallingCodeEditable={false}
              value={phone1}
              onChange={(e) => handelphoneChange("ph1", e)}
            />
            <div className="error">
              {simpleValidator.current.message(
                "phone1",
                inputs.phone1,
                "required"
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">رقم الهاتف 2</label>
            {/* <input
              type="number"
              id=""
              className="form-control"
              name="phone2"
              value={inputs.phone2}
              onChange={handelInputChange}
              onBlur={() => simpleValidator.current.showMessageFor("phone2")}
            /> */}

            <PhoneInput
              className="form-control"
              international
              countrySelectProps={{ unicodeFlags: true }}
              placeholder="Enter phone number"
              defaultCountry="EG"
              countryCallingCodeEditable={false}
              value={phone1}
              onChange={(e) => handelphoneChange("ph2", e)}
            />
            <div className="error">
              {simpleValidator.current.message(
                "phone2",
                inputs.phone2,
                "required"
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">المؤهل الدراسي</label>
            <input
              type="text"
              name="education"
              value={inputs.education}
              onChange={handelInputChange}
              id=""
              className="form-control"
              onBlur={() => simpleValidator.current.showMessageFor("education")}
            />
            <div className="error">
              {simpleValidator.current.message(
                "education",
                inputs.education,
                "required"
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">الدوله</label>
            <CountrySelector setInputs={setInputs} />
            {/* <input
              type="text"
              name="country"
              value={inputs.country}
              onChange={handelInputChange}
              id=""
              className="form-control"
              onBlur={() => simpleValidator.current.showMessageFor("country")}
            /> */}
            <div className="error">
              {simpleValidator.current.message(
                "country",
                inputs.country,
                "required"
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group">
            <label htmlFor="">المحافظه</label>
            <input
              type="text"
              name="city"
              value={inputs.city}
              onChange={handelInputChange}
              className="form-control"
              onBlur={() => simpleValidator.current.showMessageFor("city")}
            />
            <div className="error">
              {simpleValidator.current.message("city", inputs.city, "required")}
            </div>
          </div>
        </div>

        {courseId == "" ? (
          <div className="col-lg-6">
            <div className="form-group">
              <label htmlFor="">الكورس</label>
              <select
                name="courseId"
                // value={inputs.courseId}
                onChange={handelInputChange}
                id=""
                className="form-control"
              >
                <option value="0">اختر الكورس</option>
                {allcourses.map((course) =>
                  course.isActive ? (
                    <option value={course.id} key={course.id}>
                      {course.name}
                    </option>
                  ) : null
                )}
              </select>
              <div className="error">
                {simpleValidator.current.message(
                  "courseId",
                  inputs.courseId,
                  "required"
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="col-lg-12">
          <div className="form-group">
            <label htmlFor="">اسئله واستفسارات</label>
            <textarea
              name="qes"
              value={inputs.qes}
              onChange={handelInputChange}
              id=""
              className="form-control"
            ></textarea>
          </div>
        </div>

        <div className="col-lg-12">
          <div className="form-group">
            <button className="btn">التسجيل</button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default CourseForm;
