import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getAllCourses } from "../store/courseSlice";
import Select from "react-select";

function Filter({ page, setPage, filterInputs, setFilterInputs, filterData }) {
  const [loading, setLoading] = useState(true);
  const [optionselect, setOptionSelect] = useState([]);
  const [marketerselect, setMarketerselect] = useState([]);
  const dispatch = useDispatch();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFilterInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
  };
  useEffect(() => {
    dispatch(getAllCourses())
      .unwrap()
      .then((data) => {
        const newCourses = data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        // console.log(newSkills);
        setOptionSelect(newCourses);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);

        setLoading(false);
      });
  }, [dispatch]);

  const onSelectChange = (value, action) => {
    const courseIds = value.map((item) => item.value);
    let modifiedArray = courseIds.map((item) => "&courseIds=" + item).join("");
    setFilterInputs((prevInputs) => ({
      ...prevInputs,
      courseIds: modifiedArray,
    }));
  };

  const onMarketerChange = (value, action) => {
    const markaterId = value.value;
    setFilterInputs((prevInputs) => ({
      ...prevInputs,
      markterId: markaterId,
    }));
  };

  return (
    <>
      <form action="" onSubmit={filterData}>
        <div className="form_wrapper">
          <div className="form_group">
            <label htmlFor="">حالة الاتصال</label>
            <select
              name="isCalled"
              id=""
              onChange={handelChange}
              value={filterInputs.isCalled}
              className="form_control"
            >
              <option value="0">اختر الحاله</option>
              <option value="true">تم الاتصال</option>
              <option value="false">لم يتم الاتصال</option>
            </select>
          </div>

          <div className="form_group">
            <label htmlFor="">تاريخ البدايه</label>
            <input
              type="date"
              name="startDate"
              id=""
              value={filterInputs.startDate}
              onChange={handelChange}
              className="form_control"
            />
          </div>
          <div className="form_group">
            <label htmlFor="">تاريخ النهايه</label>
            <input
              type="date"
              name="endDate"
              id=""
              value={filterInputs.endDate}
              onChange={handelChange}
              className="form_control"
            />
          </div>
        </div>
        <div className="form_group">
          <label htmlFor="">التدريبات</label>
          <Select
            closeMenuOnSelect={false}
            isMulti
            name="colors"
            // value={selectVal}
            options={optionselect}
            className="basic-multi-select"
            classNamePrefix="select"
            id="SkillsTags"
            onChange={onSelectChange}
            // onInputChange={handelFilterChange}
            // hideSelectedOptions={false}
          />
        </div>
        <div className="form_group">
          <button className="btn_style">تصفيه</button>
        </div>
      </form>
    </>
  );
}

export default Filter;
