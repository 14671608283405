import React from "react";
import { mainUrl } from "./MainUrls";
import { Link } from "react-router-dom";

function CourseCard({ course, markterId }) {
  const urlCourseName = course.name.replace(/\s/g, "");
  return (
    <>
      <div className="col-md-4 col-lg-2">
        <div className="course_card">
          <Link
            to={`/course-details/${course.id}/${markterId}/${urlCourseName}`}
          >
            <div className="card-img">
              <div className="img-parent">
                <img src={`${mainUrl}/${course.imageUrl}`} alt="" />
              </div>
            </div>
            <div className="card_body">
              <h5 className="card_title">{course.name}</h5>
            </div>

            <button
              target="_blank"
              className="d-block btn btn-dark w-100 text-white"
            >
              التفاصيل
            </button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
