import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
const endpoint = 'https://backend.egy-courses.com/api/'


export const saverequest = createAsyncThunk(
  "requests/saverequest",
  async (requestData, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;
    try {
      const res = await axios.post(
        `${endpoint}FormRequests`, requestData
      );
      const data = await res.data;
      console.log(data);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
); 




const requestSlice = createSlice({
  name: "requests",
  initialState: {
    isLoading: true,
  },
  reducers: {},
  extraReducers: {
    [saverequest.pending]: (state, action) => {
      state.isLoading = true;
    },
    [saverequest.fulfilled]: (state, action) => {
      state.isLoading = false;
    },
    [saverequest.rejected]: (state, action) => {
      state.isLoading = false;
    },
  }
});


export default requestSlice.reducer;