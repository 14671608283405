import React, { useEffect, useState } from "react";
import Loading from "../components/Loading";
import { useDispatch } from "react-redux";
import { markaterLogin } from "../store/markaterSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    phone: "",
    password: "",
  });

  const handelInputChange = (event) => {
    const { name, value } = event.target;
    setInput((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  useEffect(() => {
    Cookies.remove("user_id");
    Cookies.remove("user_name");
    Cookies.remove("user_phone");
    Cookies.remove("user_balance");
  }, []);

  const saveData = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(input);
    dispatch(markaterLogin(input))
      .unwrap()
      .then((res) => {
        toast.success("تم تسجيل الدخول بنجاح")
        console.log(res);
        Cookies.set("user_id", res.id);
        Cookies.set("user_name", res.name);
        Cookies.set("user_phone", res.phone);
        Cookies.set("user_balance", res.balacnce);
        setTimeout(() => {
          setLoading(false);
          navigate("/agent/dashboard");
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        console.error(err.data);
        toast.error(`none`);
        setLoading(false);
      });
  };

  return (
    <>
      {loading ? <Loading open={true} /> : ""}
      <div className="login_page">
        <img
          className="wavelogin"
          src={`${process.env.PUBLIC_URL}/images/wavelogin.svg`}
          alt=""
        />
        <div className="container">
          <div className="login_form">
            <div className="logo">
              <img src={`${process.env.PUBLIC_URL}/images/logo.svg`} alt="" />
            </div>
            <form action="" onSubmit={saveData}>
              <div className="form-group">
                <label htmlFor="">رقم الهاتف</label>
                <input
                  name="phone"
                  value={input.phone}
                  type="text"
                  className="form-control"
                  placeholder="example@example.com"
                  onChange={handelInputChange}
                />
              </div>
              <div className="form-group">
                <label htmlFor="">كلمه المرور</label>
                <input
                  name="password"
                  value={input.password}
                  type="password"
                  className="form-control"
                  placeholder="**********"
                  onChange={handelInputChange}
                />
              </div>
              <div className="btn_submit">
                <button className="btn">تسجيل الدخول</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
