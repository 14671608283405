import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { markaterEdit } from "../../store/markaterSlice";
import { toast } from "react-toastify";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AgentSetting({
  openDialog,
  setOpenDialog,
  markaterId,
  markatername,
  setLoading,
  markaterPass,
  markaterPhone,
  markaterbalancs,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [nameData, setNameData] = React.useState({
    id: "",
    name: "",
    phone: "",
    password: "",
    balacnce: "",
  });

  React.useEffect(() => {
    setNameData((prevInputs) => ({ ...prevInputs, name: markatername }));
    setNameData((prevInputs) => ({ ...prevInputs, phone: markaterPhone }));
    setNameData((prevInputs) => ({ ...prevInputs, password: markaterPass }));
    setNameData((prevInputs) => ({ ...prevInputs, balacnce: markaterbalancs }));
    setNameData((prevInputs) => ({ ...prevInputs, id: markaterId }));
  }, [markatername, markaterPass]);

  const handleClose = () => {
    setOpenDialog(false);
  };
  const dispatch = useDispatch();

  const handelChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setNameData((prevInputs) => ({ ...prevInputs, [name]: value }));
  };

  const saveData = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(nameData.name);
    dispatch(markaterEdit({ markaterId: markaterId, markaterData: nameData }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        setOpenDialog(false);
        toast.success("تم التعديل بنجاح");
        setTimeout(() => {
          window.location.reload();
        }, 500);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
        keepMounted
        onClose={handleClose}
        className="skills_dialog"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle>{"الاعدادات"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <form onSubmit={saveData} className="edit_form">
            <label htmlFor="" style={{ marginBottom: "10px"}}>تعديل  الاسم</label>
              <input
                type="text"
                placeholder="تعديل الاسم"
                value={nameData.name}
                name="name"
                onChange={handelChange}
              />

              <label htmlFor="" style={{marginTop: "20px", marginBottom: "10px"}}>تعديل الرقم السري</label>
              <input
                type="text"
                placeholder="تعديل الرقم السري"
                value={nameData.password}
                name="password"
                onChange={handelChange}
              />
              <button type="submit" className="mt-3 btn">
                حفظ
              </button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>اغلاق</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
