import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'

function CountrySelector({setInputs}) {
  const [value, setValue] = useState('')
  const options = useMemo(() => countryList().getData(), [])

  const changeHandler = value => {
    console.log(value)
    setValue(value)
    setInputs((prevInputs) => ({ ...prevInputs, country: value.label }));
  }

  return <Select className="form-control" options={options} value={value} onChange={changeHandler} />
}

export default CountrySelector