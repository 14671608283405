import React, { useEffect, useState } from "react";
import DataGridDemo from "../../components/DataTable";
import { useDispatch, useSelector } from "react-redux";
import {
  markaterInfo,
  markaterRequests,
  formRequests,
} from "../../store/markaterSlice";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Loading from "../../components/Loading";
import AgentSetting from "./AgentSetting";
import Pagination from "@mui/material/Pagination";
import Filter from "../../components/Filter";
function AgentDashboard() {
  const selectionRows = false;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const domainpath = window.location.origin;
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [markaterId, setMarkaterId] = useState();
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState();
  const [page, setPage] = React.useState(1);
  const [pageCount, setPageCount] = useState();
  const [filterInputs, setFilterInputs] = useState({
    markterId: Cookies.get("user_id"),
    courseId: "",
    startDate: "",
    endDate: "",
    courseIds: [],
    isCalled: "",
    statusId: "",
    endChangeDate: "",
    startChangeDate: "",
  });
  const markaterRequestsData = useSelector(
    (state) => state.markaterSlice.markaterformRequestsData
  );
  const [markaterInformation, setMarkaterInformation] = useState({});
  const handleRowSelection = (selectionModel) => {
    setRowSelectionModel(selectionModel);
    // setInputs((prevInputs) => ({ ...prevInputs, district_id: selectionModel }));
    // console.log("Selected Rows:", selectionModel);

    // const selectedRowData = selectionModel.map((selectedId) =>
    //   rows.find((row) => row.id === selectedId)
    // );
    // console.log("Selected Row Data:", selectedRowData);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const agentlogout = () => {
    Cookies.remove("user_id");
    Cookies.remove("user_name");
    Cookies.remove("user_phone");
    Cookies.remove("user_balance");
    navigate("/agent");
    window.location.reload();
    toast.success(`تم تسجيل الخروج بنجاح`);
  };

  useEffect(() => {
    const markateruser_id = Cookies.get("user_id");
    const markaterphone = Cookies.get("user_phone");
    const markatername = Cookies.get("user_name");
    const markaterbalance = Cookies.get("user_balance");
    setMarkaterId(markateruser_id);
    console.log(markateruser_id);

    dispatch(markaterInfo(markateruser_id))
      .unwrap()
      .then((res) => {
        setMarkaterInformation(res);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    const markateruser_id = Cookies.get("user_id");
    const markaterphone = Cookies.get("user_phone");
    const markatername = Cookies.get("user_name");
    const markaterbalance = Cookies.get("user_balance");
    setMarkaterId(markateruser_id);
    console.log(markateruser_id);
    dispatch(formRequests({ ...filterInputs, pageNumber: page }))
      .unwrap()
      .then((res) => {
        setLoading(false);
        setPageCount(res.totalPages);
        console.log(res, "sucess");
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setLoading(false);
      });
  }, [dispatch, page]);
  const filterData = (e) => {
    setLoading(true);
    e.preventDefault();
    // console.log(filterInputs);
    setPage(1);
    dispatch(
      formRequests({
        markterId: filterInputs.markterId,
        courseId: filterInputs.courseId,
        startDate: filterInputs.startDate,
        endDate: filterInputs.endDate,
        courseIds: filterInputs.courseIds,
        pageNumber: page,
        isCalled: filterInputs.isCalled,
        statusId: filterInputs.statusId,
        startChangeDate: "",
        endChangeDate: "",
      })
    )
      .unwrap()
      .then((res) => {
        setLoading(false);
        setPageCount(res.totalPages);
        console.log(res);
        // var nmodifiedArray = res.map((item) => ({
        //   ...item, // Keep all the existing properties
        //   courseName: item?.courseModel?.name, // Add courseName property
        //   markterName: item?.markterModel?.name, // Add markterName property
        //   orderStatusName: item?.orderStatusModel?.name,
        //   orderDate: item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
        //   iscalled2: item.isCalled ? "تم الاصال" : "لم يتم الاتصال"
        // }));

        // setModifiedArray(nmodifiedArray)
      })
      .catch((er) => {
        // console.log(er);
        setLoading(false);
      });
  };

  var modifiedArray =
    markaterRequestsData?.length > 0 &&
    markaterRequestsData.map((item) => ({
      ...item, // Keep all the existing properties
      isCalled2: item.isCalled ? "تم الاتصال" : "لم يتم الاتصال",
      orderDate:
        item.timeCreated != null ? item?.timeCreated.split("T")[0] : "",
      orderTime:
        item.timeCreated != null
          ? item?.timeCreated.split("T")[1].split(".")[0]
          : "",
      courseModel: item.courseModel?.name,
      orderStatusModel: item.orderStatusModel?.name,
    }));

  const coulmns = [
    { field: "orderDate", headerName: "التاريخ", width: 150 },
    { field: "orderTime", headerName: "الوقت", width: 150 },
    { field: "arName", headerName: "الاسم", width: 150 },
    { field: "phone1", headerName: "الهاتف الاول", width: 150 },
    { field: "phone2", headerName: "الهاتف الثاني", width: 150 },
    {
      field: "courseModel",
      headerName: "اسم الكورس",
      width: 200,
    },
    {
      field: "orderStatusModel",
      headerName: "حاله الحجز",
      width: 150,
    },
    { field: "isCalled2", headerName: "حالة الاتصال", width: 150 },

    // {
    //   field: "enName",
    //   headerName: "الاسم انجليزي",
    //   editable: true,
    //   width: 200,
    // },
    { field: "country", headerName: "الدوله", width: 150 },
    { field: "education", headerName: "المؤهل", width: 150 },
    { field: "city", headerName: "المدينه", width: 150 },
  ];

  const copyAgentLink = () => {
    const agentLinkText = document.getElementById("agentLinkContent").innerText;

    // Create a textarea element to copy the text
    const textarea = document.createElement("textarea");
    textarea.value = agentLinkText;
    document.body.appendChild(textarea);

    // Select the text within the textarea
    textarea.select();
    textarea.setSelectionRange(0, 99999); // For mobile devices

    // Copy the selected text
    document.execCommand("copy");

    // Remove the textarea as it's no longer needed
    document.body.removeChild(textarea);

    // Alert or indicate that the text has been copied (optional)
    alert("Link copied!");
  };

  const changeSettingOpen = () => {
    setOpenDialog(true);
  };

  return (
    <>
      {loading ? <Loading /> : null}

      <div className="agent_dashboard">
        <AgentSetting
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          markaterId={markaterId}
          markatername={markaterInformation.name}
          markaterPass={markaterInformation.password}
          markaterPhone={markaterInformation.phone}
          markaterbalancs={markaterInformation.balacnce}
          loading={loading}
          setLoading={setLoading}
        />
        <div className="agentDash_navbar">
          <div className="container">
            <button className="btn" onClick={changeSettingOpen}>
              <i className="fas fa-cog"></i> <span>الاعدادات</span>
            </button>
          </div>
        </div>
        <div className="container">
          <div className="top_header themarkterLink d-flex align-items-center justify-content-between">
            <div>
              <h5>رابط التسويق الخاص بك هو</h5>
              <p
                className="agent_link"
                id="agentLinkContent"
              >{`${domainpath}/affiliatemarketing/${markaterId}`}</p>

              <button className="btn btn-dark" onClick={copyAgentLink}>
                نسخ الرابط
              </button>
            </div>
          </div>
          <hr />
          <div className="top_header d-flex align-items-center justify-content-between">
            <div>
              <h5>مرحبا بك</h5>
              <p>{markaterInformation.name}</p>
            </div>
            <button className="btn btn_style" onClick={agentlogout}>
              تسجيل الخروج
            </button>
          </div>
          <hr />
          <div className="top_header top_header_balance d-flex align-items-center justify-content-between">
            <div>
              <h5>الرصيد الكلي</h5>
            </div>
            <h5 className="balance">{markaterInformation.balacnce} ج.م</h5>
          </div>
          <hr />
          <div className="table_filter">
            <h5 className="head">تصفيه</h5>
            <Filter
              page={page}
              setPage={setPage}
              filterInputs={filterInputs}
              setFilterInputs={setFilterInputs}
              filterData={filterData}
            />
          </div>
          <div className="balance_table">
            <h5>تفاصيل الطلبات</h5>
            {markaterRequestsData?.items?.length >= 0 && (
              <DataGridDemo
                selectionRows={selectionRows}
                coulmns={coulmns}
                rows={markaterRequestsData.items}
                handleRowSelection={handleRowSelection}
              />
            )}
            <Pagination
              count={pageCount}
              page={page}
              onChange={handleChange}
              className="paginaitonMod"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentDashboard;
